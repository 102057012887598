import React, { useState, useEffect } from 'react';
import { useAccount, WagmiConfig, createClient } from 'wagmi';
import { ConnectButton, RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';
import {  chain, configureChains } from 'wagmi';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import beast from './beast.png';
import './App.css';
import gif from './gif.gif'
import { ethers } from 'ethers';
import ABI from './ABI.json'


const { chains, provider } = configureChains(
  [chain.mainnet],
  [alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

function Mint() {
  const account = useAccount();
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [price, setMintPrice] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  
  const [success, setSuccess] = useState()

  const handleAmountChange = (event) => {
    setMintAmount(event.target.value);
  };

  const contractAddress = '0xCA93C4cDFbEA225FB32c828bb9A81f141d165F26';

  useEffect(() => {
    fetch('price.json')
      .then((response) => response.json())
      .then((data) => setMintPrice(data.price));

    console.log('Account address changed. New address:', account.address);
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);
  

  const mintNFT = async () => {
    console.log('Minting NFT. Account state:', account);

    try {
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
      const signer = await account.connector.getSigner();
      if (!signer) {
        alert('Unable to get signer from the connected wallet.');
        return;
      }
      const contract = new ethers.Contract(contractAddress, ABI, signer);
  
      const priceInWei = ethers.utils.parseEther(price.toString());
      const totalValue = priceInWei.mul(ethers.BigNumber.from(mintAmount));
  
      let transaction = await contract.mint(mintAmount, {
        value: totalValue
      });  
  
      await transaction.wait();
      setSuccess(true)
    } catch (error) {
      if(error.message.includes('insufficient funds')){
        alert("You don't have enough funds!");
      }else{
        console.error('An error occurred!', error);
      }
    }
  };
  

  return (
    <div>
      {account ? <h2 className="subtitle">Mint Price:<br></br> {(price * mintAmount)} ETH</h2> : <h2 className="subtitle">Mint Price:<br></br> {(price * mintAmount).toFixed(5)} ETH</h2>}
      <h2 className='subtitle2'>{mintAmount}</h2>
      <input 
        type="range" 
        min="1" 
        max="50" 
        value={mintAmount} 
        onChange={handleAmountChange}
        style={{width: '100%'}}
      />
      <ConnectButton></ConnectButton>
      
      <button className='mint-button' onClick={mintNFT}>Mint</button>
      {success ? <h2 className="subtitle" style={{fontStyle:'normal', marginTop:'5px'}}>NFT Minted Successfully</h2> : null}
    </div>
  );
}

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={darkTheme({
      accentColor: '#457bb8',
      accentColorForeground: 'white',
      borderRadius: 'large',
      fontStack: 'system',
      overlayBlur: 'small',
    })}  chains={chains}>
      <div className="app">
        <main className="main-content">
          <div className="left-content">
            <h1 className="title">Mint Your OG Hype Beast</h1>
            <Mint />
          </div>
          <div className="right-content">
            <img src={gif} alt="Beast" className="right-side-image" />
          </div>
        </main>
      </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
